import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, Ref, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { ModifyPasswordService, LoginService } from '@/sevices';
import { RsaEncrypt } from '@/common/rsg';
let Password = class Password extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.submiting = false;
        this.form = {
            password: '',
        };
        this.rules = {
            password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        };
    }
    get baseUserId() {
        let publicUserInfo = localStorage.getItem('publicUserInfo') || '';
        try {
            publicUserInfo = JSON.parse(publicUserInfo);
            return publicUserInfo?.baseUserId || '';
        }
        catch (error) {
            return '';
        }
    }
    onChangeValue(v) {
        this.visible = v;
        if (!v) {
            this.cform.clearValidate();
            this.cform.resetFields();
        }
    }
    comfirm() {
        const isValid = this.cform.validate();
        if (!isValid)
            return;
        this.submiting = true;
        LoginService.getKey().then((resKey) => {
            if (!resKey.success)
                return;
            const dataKey = resKey.data;
            const flag = typeof dataKey === 'object' && dataKey.index && dataKey.key;
            if (!flag)
                return;
            const { index, key } = dataKey;
            const password = RsaEncrypt(this.form.password, key);
            return ModifyPasswordService.checkOldPassword({
                index: index,
                oldPassword: password,
            }, this.baseUserId);
        }).then((res) => {
            if (res.success && res.data) {
                this.$emit('ok', (data) => {
                    if (data.success) {
                        this.cancel();
                    }
                });
            }
            else {
                this.$message.error('密码输入错误，无法删除');
            }
        }).finally(() => {
            this.submiting = false;
        });
    }
    cancel() {
        this.$emit('input', false);
    }
};
__decorate([
    Prop()
], Password.prototype, "value", void 0);
__decorate([
    Ref()
], Password.prototype, "cform", void 0);
__decorate([
    Watch('value')
], Password.prototype, "onChangeValue", null);
Password = __decorate([
    Component({
        name: 'Password',
        components: {
            CForm: Form,
            CFormItem: FormItem,
        },
    })
], Password);
export default Password;
